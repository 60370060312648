import React from "react"
import { Helmet } from "react-helmet"
import Banner from "../components/banner"
import Fade from 'react-reveal/Fade';

class Index extends React.Component {
	constructor(props) {
		super(props);
	}

  render() {
    return (
    <div>	
    	<Helmet>
        <meta charSet="utf-8" />
        <title>Kieran Davidson</title>
       </Helmet>	
    	<Banner text="Kieran Davidson"/>
			<section className="section" name="page-content">
				<div className="container">
					<div className="content has-text-white">
				    <h3 className="is-size-2 is-size-3-mobile has-text-white">Hello, <br />I'm&nbsp;Kieran.</h3>
				  </div>
				  <div className="is-size-4 is-size-5-mobile content has-text-white">
				    <p>I do some development. Mostly games and web stuff.</p>
				    <p>I have a web design business that I started with my wife. We work with various businesses and individuals across the country. It's pretty groovy.</p>
				  </div>
				  <div className="content">
				 	  <Fade left>
						  <a className="box-button" href="https://www.belowthefold.ca">
								<span className="centered is-size-4 is-size-5-mobile">Check it out</span>
							</a>
						</Fade>
					</div>
				</div>
			</section>
			<section className="section">
				<div className="container">
				</div>
			</section>
		</div>
		);
  }
}

export default Index;
