import React from "react"
import DownArrow from "../assets/down-arrow.svg"
import Fade from 'react-reveal/Fade';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

class Banner extends React.Component {
	 constructor(props) {
    super(props);
    this.state = { show: true };
    this.scrollDown = this.scrollDown.bind(this);
  }

  scrollDown() {
		scroller.scrollTo('page-content', {
      duration: 650,
      delay: 0,
      smooth: 'easeInOutCubic'
    });
  }

	componentDidMount() {
	  window.addEventListener('scroll', this.listenToScroll)
	}

	componentWillUnmount() {
	  window.removeEventListener('scroll', this.listenToScroll)
	}

	listenToScroll = () => {
	  const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

	  if (winScroll > 50)
	  {
		  this.setState({ show: false });
		}
		else
		{
			this.setState({ show: true });
		}
	}

  render() {
    return (
    	<div style={{height: "100vh", minHeight: "350px"}}>
	    	<section className="section has-background-primary" style={{height: "100%", position: "relative"}}>
	    		<div className="container" style={{height: "100%"}}>
	    			<div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
						  <h1 className="is-size-1 is-size-2-mobile has-text-white uppercase">{this.props.text}</h1>
					  </div>	
	    		</div>
	    		<Fade top when={this.state.show}>
		    		<div className="scroller-container">
							<a href="javascript:;" onClick={this.scrollDown}><img src={DownArrow} /></a>
					  </div>
					  </Fade>
	    	</section>
    	</div>
    );
  }
}

export default Banner;